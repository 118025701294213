<template>
  <LayoutDossierPromoteurs>
    <template #adresse>{{ informations.adresse }}</template>
    <template #progress_bar
      ><progress-bar
        section="Informations"
        :progress_section="progress_section.informations"
      />
    </template>
    <template #current_page>Informations </template>
    <template #body v-if="!api_is_loading">
      <ValidateModal
        v-if="showModal"
        @isCancel="isCancel"
        @isValide="saveSelection"
      >
        <slot slot="message_body">{{ message_confirmation }} Oui / Non</slot>
        <slot slot="button_valide">Oui</slot>
        <slot slot="button_cancel">Non</slot>
      </ValidateModal>

      <div class="flex">
        <ValidationObserver
          v-slot="{ handleSubmit }"
          class="w-full"
          ref="formInformations"
        >
          <div class="flex w-full my-5">
            <Map
              class="h-64-screen"
              :cadastreParcelle="infos_cadastre"
              @getInfosParcelles="getInfosParcelles"
              :is_loading="loading_cadastre"
              :informations="informations"
              ref="map_parcelle"
              @checkConfirmationForSelectParcelle="
                checkConfirmationForSelectParcelle
              "
            ></Map>
          </div>
          <form
            @submit.prevent="handleSubmit()"
            class="w-full flex flex-col mt-4 informations-form"
          >
            <div class="flex w-full flex-col">
              <div
                class="lg:w-full xl:w-full flex sm:flex-col lg:mb-6 space-x-8 sm:space-x-0"
              >
                <div
                  class="flex flex-col lg:w-full xl:w-6/24 space-y-3 xl:pr-2 lg:pr-4 sm:pr-0 lg:space-y-6 lg:mb-6"
                >
                  <template v-if="currentStatus != 6">
                    <div
                      class="flex items-stretch"
                      style="margin: 20px 0 !important"
                    >
                      <Checkbox
                        id="is_anonymous"
                        :val="informations.is_anonymous"
                        v-model="informations.is_anonymous"
                        class="pr-10"
                      />
                      <label
                        for="is_anonymous"
                        class="cursor-pointer text-promy-gray-650 text-sm flex items-center mt-1"
                        >mode anonyme</label
                      >
                    </div>
                  </template>
                  <template v-if="currentStatus == 6">
                    <div
                      class="flex items-stretch w-fit"
                      style="margin: 20px 0 !important"
                      v-tooltip.bottom="{
                        content: 'Ne pas afficher côté promoteur',
                        classes: classes,
                      }"
                    >
                      <Checkbox
                        id="is_not_visible_when_vendu"
                        :val="informations.is_not_visible_when_vendu"
                        v-model="informations.is_not_visible_when_vendu"
                        class="pr-10"
                      />
                      <label
                        for="is_not_visible_when_vendu"
                        class="cursor-pointer text-promy-gray-650 text-sm flex items-center mt-1"
                        >Non visible</label
                      >
                    </div>
                  </template>
                  <template v-if="currentStatus == 2">
                    <div>
                      <div
                        class="flex items-stretch"
                        style="margin: 20px 0 !important"
                      >
                        <Checkbox
                          :val="informations.availabe_when_brouillon"
                          id="availabe_when_brouillon"
                          v-model="informations.availabe_when_brouillon"
                          class="pr-10"
                        />
                        <label
                          v-tooltip.right="{
                            content:
                              'En cochant cette case et en cliquant sur mettre à jour; vous choisissez d\'afficher ce terrain en tant que dossier bientôt disponible',
                            classes: classes,
                          }"
                          for="availabe_when_brouillon"
                          class="cursor-pointer text-promy-gray-650 text-sm flex items-center mt-1"
                          >Bientôt dispo</label
                        >
                      </div>
                    </div>
                  </template>
                  <template v-if="currentStatus == 5">
                    <div>
                      <div
                        class="flex items-stretch"
                        style="margin: 20px 0 !important"
                      >
                        <Checkbox
                          id="status_as_vendu"
                          v-model="informations.status_as_vendu"
                          class="pr-10"
                        />
                        <label
                          for="status_as_vendu"
                          class="cursor-pointer text-promy-gray-650 text-sm flex items-center mt-1"
                          v-tooltip.right="{
                            content:
                              'En cochant cette case et en cliquant sur mettre à jour; vous choisissez d\'afficher ce terrain en tant que dossier vendu',
                            classes: classes,
                          }"
                          >Vendu</label
                        >
                      </div>
                    </div>
                  </template>
                  <div class="flex flex-col my-2">
                    <label
                      for=""
                      class="capitalize text-xs text-gray-600 font-bold font-arial"
                      >Adresse</label
                    >

                    <ProAutoComplete
                      class="w-full"
                      :list="addresses"
                      v-model="informations.adresse"
                      :args="['properties', 'label']"
                      @setItem="setAddress"
                    />
                  </div>
                  <pro-input
                    shouldBeHighlighted
                    name="Titre"
                    v-model="informations.title"
                    label="Titre"
                  />
                  <div
                    class="flex flex-col"
                    v-if="Object.keys(informations.current_parcelle).length"
                  >
                    <label
                      for=""
                      class="capitalize text-gray-600 font-bold font-arial"
                      >Adresses des parcelles</label
                    >
                    <div
                      class="flex space-x-2"
                      v-for="(parcelle, idx) in [
                        informations.current_parcelle,
                        ...informations.parcelles_voisines,
                      ]"
                      :key="idx + '_address'"
                    >
                      <pro-input
                        name="ref"
                        :value="
                          parcelle.properties.section +
                          parcelle.properties.numero
                        "
                        label="Référence"
                        disabled
                      />
                      <pro-input
                        shouldBeHighlighted
                        :class="
                          !parcelle.properties.adresse ? 'animate-pulse' : ''
                        "
                        :name="idx + '_input_address'"
                        v-model="parcelle.properties.adresse"
                        label="Adresse"
                      />
                    </div>
                  </div>

                  <pro-input
                    shouldBeHighlighted
                    name="Commune"
                    v-model="informations.commune"
                    label="Commune"
                  />

                  <pro-input
                    shouldBeHighlighted
                    name="Code postal"
                    v-model="informations.code_postal"
                    label="Code postal"
                    type="number"
                  />

                  <pro-input
                    shouldBeHighlighted
                    name="Surface terrain"
                    v-model.number="informations.surface_terrain"
                    label="Surface terrain"
                    type="number"
                  />

                  <pro-input
                    shouldBeHighlighted
                    name="Surface bati"
                    v-model.number="informations.surface_bati"
                    label="Surface bâti"
                    type="number"
                  />

                  <div class="flex flex-col">
                    <label>Image terrain</label>

                    <InputImage
                      @resetImage="getImageSatelliteView(true)"
                      :file="informations.image_terrain"
                      @upload="saveImage"
                      :selfUploader="true"
                      @deleteImage="informations.image_terrain = ''"
                      :withResetImage="with_reset_image"
                    />
                    <HighlightMessage
                      :show="isEmptyOrNull(informations.image_terrain)"
                    />
                  </div>
                </div>
                <div
                  class="flex flex-col lg:w-full xl:w-6/24 lg:space-y-6 space-y-3 xl:pl-2"
                >
                  <pro-input
                    shouldBeHighlighted
                    name="Departement"
                    v-model="informations.departement"
                    label="Département"
                    type="number"
                  />

                  <pro-input
                    shouldBeHighlighted
                    name="sdp"
                    v-model.number="informations.sdp"
                    label="sdp"
                    type="number"
                  />

                  <pro-input
                    shouldBeHighlighted
                    name="Nombre de lot"
                    v-model.number="informations.nmbre_lots"
                    label="Nombre de lots"
                    type="number"
                  />

                  <pro-input
                    shouldBeHighlighted
                    name="CA potentiel"
                    v-model.number="informations.ca_potentiel"
                    label="CA potentiel"
                    type="number"
                  />

                  <pro-input
                    shouldBeHighlighted
                    name="Prix plancher"
                    v-model.number="informations.prix_plancher"
                    label="Prix plancher"
                    type="number"
                  />
                </div>
                <div
                  class="flex flex-col lg:w-full xl:w-6/24 lg:space-y-6 space-y-3 xl:pl-2"
                >
                  <div class="">
                    <div class="flex flex-col my-2">
                      <label class="z">Démolition </label>
                      <div class="flex space-x-4 pt-3">
                        <ProRadio
                          :value="'Oui'"
                          @input="informations.demolution = $event"
                          name="demolution"
                          id="demolution_oui"
                          label="Oui"
                          :checked="informations.demolution === 'Oui'"
                          :required="true"
                        >
                        </ProRadio>
                        <ProRadio
                          :value="'Non'"
                          @input="informations.demolution = $event"
                          name="demolution"
                          id="demolution_non"
                          label="Non"
                          :checked="informations.demolution === 'Non'"
                          :required="true"
                        >
                        </ProRadio>
                      </div>
                      <HighlightMessage
                        :show="isEmptyOrNull(informations.demolution)"
                      />
                    </div>
                  </div>
                  <div class="">
                    <div class="flex flex-col my-2">
                      <TypeDeProject
                        @changeProjectType="changeProjectType"
                        :typeProjet="informations.type_bien"
                      />
                      <HighlightMessage
                        :show="isEmptyOrNull(informations.type_bien)"
                      />
                    </div>
                  </div>
                  <div>
                    <pro-input
                      shouldBeHighlighted
                      :mask="'########'"
                      name="code INSEE"
                      v-model="informations.code_insee"
                      label="code INSEE"
                      type="number"
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="mb-1">description cadastre </label>
                    <quill-editor
                      ref="myQuillEditor"
                      class="mb-16 md:mb-28 sm:mb-2"
                      v-model="informations.cadastre_desc"
                    />
                    <HighlightMessage
                      :show="
                        isRequired
                          ? isEmptyOrNull(informations.cadastre_desc)
                          : false
                      "
                    />
                  </div>
                  <div class="flex flex-col">
                    <label class="mb-1">description altimétrie </label>
                    <quill-editor
                      ref="myQuillEditor"
                      class="mb-16 md:mb-28 sm:mb-2"
                      v-model="informations.altimetrie_desc"
                    />
                    <HighlightMessage
                      :show="
                        isRequired
                          ? isEmptyOrNull(informations.altimetrie_desc)
                          : false
                      "
                    />
                  </div>
                </div>
              </div>
              <div class="flex w-full mt-4 mb-15" v-if="!isAppelAProjet()">
                <div class="descriptif-bati flex flex-col w-1/2 lg:w-full">
                  <div class="flex justify-between items-center">
                    <pro-input
                      classesInput="w-fit"
                      shouldBeHighlighted
                      name=""
                      requirements="required"
                      v-model="informations.descriptif_bati_existant.title"
                      label=""
                      :class="{
                        'opacity-30':
                          !informations.descriptif_bati_existant.isShown,
                      }"
                    />
                    <div class="h-9 pt-2 flex items-center justify-center">
                      <img
                        v-if="!informations.descriptif_bati_existant.isShown"
                        src="/images/eye-off-outline.svg"
                        @click="
                          informations.descriptif_bati_existant.isShown = true
                        "
                        v-tooltip.top="{
                          content: 'Affichez',
                          classes: classes,
                        }"
                      />
                      <img
                        v-if="informations.descriptif_bati_existant.isShown"
                        src="/images/eye-outline.svg"
                        @click="
                          informations.descriptif_bati_existant.isShown = false
                        "
                        v-tooltip.top="{
                          content: 'Masquez',
                          classes: classes,
                        }"
                      />
                    </div>
                  </div>
                  <div class="flex w-full">
                    <quill-editor
                      ref="myQuillEditor"
                      class="w-full"
                      v-model="informations.descriptif_bati_existant.value"
                      :class="{
                        'opacity-30':
                          !informations.descriptif_bati_existant.isShown,
                      }"
                    />
                  </div>
                  <HighlightMessage
                    classes="w-6/10 relative top-13"
                    :show="
                      isEmptyOrNull(
                        informations.descriptif_bati_existant.value,
                      ) && informations.descriptif_bati_existant.isShown
                    "
                  />
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </template>
    <template #btn-actions>
      <sauvgard-publier-buttons @saveInfos="saveInfos()" />
    </template>
  </LayoutDossierPromoteurs>
</template>
<script>
import _ from 'lodash'
import { directive as onClickaway } from 'vue-clickaway'
import { mapGetters } from 'vuex'
import Map from './Map'
import progress from '@/mixins/dossierPromoteurs/progress'
import helpers from '@/mixins/helpers'
import parcelle from '@/mixins/cadastre/parcelle'
import urbanisme from '@/mixins/dossierPromoteurs/urbanisme'

let init_informations = {
  center_parcelle_principale: [],
  adresse: '',
  commune: '',
  code_postal: '',
  ca_potentiel: '',
  surface_bati: null,
  surface_terrain: null,
  taille_murs: [],
  sdp: '',
  nmbre_lots: '',
  departement: '',
  prix_plancher: '',
  demolution: null,
  type_bien: '',
  parcelles_voisines: [],
  current_parcelle: {},
  current_batiment: [],
  altimetries: [],
  image_terrain: null,
  code_insee: null,
  altimetrie_desc: null,
  cadastre_desc:
    "Le terme cadastre est à la fois utilisé pour désigner l'ensemble des documents relatifs au découpage en propriétés, bâties et non bâties, d'une commune française et à leur valeur, ce qui sert notamment de base de calcul à la taxe foncière, et l'administration chargée de conserver ces documents et de les mettre à jour",
  date_debut: null,
  date_fin: null,
  is_vente_privee: false,
  is_anonymous: false,
  title: '',
  descriptif_bati_existant: {
    isShown: true,
    title: 'Descriptif bâti existant',
    value: null,
  },
}
export default {
  mixins: [progress, helpers, parcelle, urbanisme],

  directives: {
    onClickaway: onClickaway,
  },

  components: {
    Map,
  },

  data() {
    return {
      classes: [
        'text-promy-gray-600',
        'px-3',
        'py-2',
        'z-50',
        'rounded-box',
        'shadow-promy-lg',
        'text-xs',
        'bg-white',
        'mt-2',
      ],
      excludedInputs: ['altimetrie_desc', 'cadastre_desc'],
      filedInputs: [
        'adresse',
        'commune',
        'code_postal',
        'ca_potentiel',
        'surface_bati',
        'surface_terrain',
        'sdp',
        'nmbre_lots',
        'departement',
        'prix_plancher',
        'demolution',
        'type_bien',
        'code_insee',
        'altimetrie_desc',
        'cadastre_desc',
        'image_terrain',
        'descriptif_bati_existant',
      ],
      loading_permis_construire_logements: false,
      loading_permis_construire_locaux: false,
      loading_dvf: false,
      loading_poi: false,
      loading_cadastre: false,
      message_confirmation: 'Voulez-vous sauvegarder votre sélection  ?',
      old_current_parcelle: {},
      old_parcelle_voisines: [],
      addresses: [],
      old_label: '',
      informations: _.merge({}, init_informations),
      isTyping: false,
      errors: [],
      is_radio: null,
      showModal: false,
    }
  },

  computed: {
    isRequired() {
      return this.informations.is_anonymous ? false : true
    },
    filteredFields() {
      return this.informations.is_anonymous
        ? this.filedInputs.filter((field) => {
            this.excludedInputs = this.isAppelAProjet()
              ? this.excludedInputs.push('descriptif_bati_existant')
              : this.excludedInputs
            if (!this.excludedInputs.includes(field)) return field
          })
        : this.filedInputs
    },
    ...mapGetters({
      informationsStore: 'dossierpromoteur/getinfos',
      infos_cadastre: 'parcelle/infos_cadastre',
      is_parcelle_changed: 'parcelle/is_parcelle_changed',
      is_parcelle_aggregate: 'parcelle/is_parcelle_aggregate',
      is_corner_changed: 'parcelle/is_corner_changed',
      markers_altimetrie: 'parcelle/markers_altimetrie',
      progress_section: 'dossierpromoteur/getProgressSection',
      api_is_loading: 'loading/is_loading',
      localisation: 'dossierpromoteur/getLocalisation',
      etude_commerciale: 'dossierpromoteur/getEtudeCommerciale',
      rapportTechniqueStore: 'dossierpromoteur/getRapportTechnique',
      getUrbanisme: 'dossierpromoteur/getUrbanisme',
      getLocalisation: 'dossierpromoteur/getLocalisation',
      currentStatus: 'dossierpromoteur/getCurrentStatus',
    }),
    refForm() {
      return this.$refs.formInformations
    },
    all_apis_is_loaded() {
      return (
        !this.loading_permis_construire_logements &&
        !this.loading_permis_construire_locaux &&
        !this.loading_dvf &&
        !this.loading_poi &&
        !this.loading_cadastre
      )
    },
    with_reset_image() {
      return this.informations.center_parcelle_principale.length > 0
    },
  },

  created() {
    this.id_dossier = this.$route.params.id
    if (this.id_dossier && this.api_is_loading) {
      this.$store
        .dispatch('dossierpromoteur/getDossierPromoteur', this.id_dossier)
        .then(() => {
          this.informations = Object.assign(
            {},
            this.informations,
            this.informationsStore,
          )
          if (!this.informations.descriptif_bati_existant.value) {
            this.getContextGeneral()
          }
          this.$store.commit('loading/set_loading', false)
          this.setAddress(
            this.informations.center_parcelle_principale,
            true,
            false,
          )
          this.getImageSatelliteView()
          this.getInfosAltimetrie()
        })
    } else {
      this.$store.commit('loading/set_loading', false)
      this.informations = Object.assign(
        {},
        this.informations,
        this.informationsStore,
      )
      this.getInfosAltimetrie()
      if (!this.informations.descriptif_bati_existant.value) {
        this.getContextGeneral()
      }
    }
  },

  methods: {
    getContextGeneral() {
      this.$http.get('admin/context_general').then((response) => {
        this.informations.descriptif_bati_existant.value =
          response.data.context_general.descriptif_bati_existant
      })
    },
    resetInformations() {
      const infoAlt = this.informations.altimetrie_desc
      this.informations = _.merge({}, init_informations)
      this.informations.altimetrie_desc = infoAlt
    },
    isCancel() {
      this.showModal = false
      this.informations.parcelles_voisines = this.old_parcelle_voisines
      if (this.is_parcelle_changed) {
        this.informations.current_parcelle = Object.assign(
          {},
          this.old_current_parcelle,
        )
        this.$refs.map_parcelle.$refs.ChangeParcelle.validateSelectParcelle(
          false,
        )
      } else if (this.is_parcelle_aggregate) {
        this.$refs.map_parcelle.$refs.AggregateParcelle.addListenerClickAggregateParcelle()
      }

      this.$refs.map_parcelle.loadDataToMap()
    },
    saveSelection() {
      this.showModal = false
      if (this.is_parcelle_changed) {
        this.$refs.map_parcelle.$refs.ChangeParcelle.validateSelectParcelle()
        let center_parcelle = this.$turf.pointOnFeature(
          this.informations.current_parcelle,
        ).geometry.coordinates
        this.$http
          .get('admin/reverse-address', {
            params: {
              lon: center_parcelle[0],
              lat: center_parcelle[1],
            },
          })
          .then(
            (response) => {
              let features = response.data.url_gouv_reverse.features
              if (response.data.url_gouv_reverse.features.length) {
                this.informations.adresse = features[0].properties.label
              }
            },
            (error) => {
              console.error(error)
            },
          )
      } else if (this.is_parcelle_aggregate) {
        this.$refs.map_parcelle.$refs.AggregateParcelle.addListenerClickAggregateParcelle()
      }
    },

    saveInfos() {
      this.informations.altimetries = this.markers_altimetrie.map((marker) => {
        return {
          coordinates: [marker.getLngLat().lng, marker.getLngLat().lat],
          elevation: marker.elevation,
        }
      })
      this.informations.date_debut = this.informationsStore.date_debut
      this.informations.date_fin = this.informationsStore.date_fin
      this.informations.is_vente_privee = this.informationsStore.is_vente_privee
      this.informations.has_pdf = this.informationsStore.has_pdf
      this.$store.commit('dossierpromoteur/setInformations', this.informations)
    },

    getInfosParcelles(data) {
      this.informations.taille_murs = data.taille_murs
      this.informations.surface_terrain = data.surface_parcelles
      this.informations.surface_bati = data.surface_batiments
    },

    changeProjectType(value) {
      this.informations.type_bien = value
    },

    getDemolution(value) {
      this.informations.demolution = value
    },

    getInfosAltimetrie() {
      if (!this.informations.altimetrie_desc) {
        this.$http.get('admin/informations-altimetrie').then((response) => {
          this.informations.altimetrie_desc = response.data.default_informations
            ? response.data.default_informations.altimetrie_desc
            : null
        })
      }
    },

    searchAfterDebounce: _.debounce(
      function () {
        this.fetchAdresses()
      },
      800, // milliseconds
    ),

    fetchAdresses() {
      this.$http
        .get('admin/gouv-url', {
          params: {
            limit: 10,
            q: this.informations.adresse,
          },
        })
        .then((response) => {
          this.addresses = response.data.hasOwnProperty('features')
            ? response.data.features
            : []
        })
    },

    setAddress(address, reload_apis = false, reset_dossier_promoteur = true) {
      if (this.informations.adresse != this.old_label || reload_apis) {
        if (reset_dossier_promoteur) {
          this.$store.commit('parcelle/resetParcelle')
          this.resetInformations()
          this.$store.commit('dossierpromoteur/resetDossierPromoteur')

          this.informations.altimetries = []
        }
        let coordinates = []
        if (address.hasOwnProperty('geometry')) {
          coordinates = address.geometry.coordinates
          this.informations.adresse = address.properties.label
        } else {
          coordinates = address
        }
        this.informations.center_parcelle_principale = coordinates.map(
          (coord) => coord.toString(),
        )
        this.getImageSatelliteView()
        this.callApis(coordinates, reset_dossier_promoteur)
      }
    },
    callApisRisque(coordinates, code_insee) {
      let params = {
        code_insee: code_insee,
        lat: coordinates[0],
        long: coordinates[1],
      }
      if (!this.getNestedObject(this.rapportTechniqueStore, 'resume')) {
        this.$store.dispatch('risques/getRisques', params).catch((err) => {
          console.log('risques not found ', err)
        })
      }
    },
    getImageSatelliteView(force_update_image = false) {
      if (this.informations.image_terrain === null || force_update_image) {
        this.informations.center_parcelle_principale
        this.$http
          .get('admin/satellite_view', {
            params: {
              lon: this.informations.center_parcelle_principale[0],
              lat: this.informations.center_parcelle_principale[1],
            },
          })
          .then((response) => {
            this.informations.image_terrain = `data:image/jpg;base64,${response.data}`
          })
      }
    },
    callApis(coordinates, reset_dossier_promoteur) {
      this.$store.commit('loading/SET_API_ZONES_URBANISME_LOADED', false)
      this.loading_cadastre = true
      this.loading_permis_construire_logements = true
      this.loading_permis_construire_locaux = true
      this.loading_dvf = true
      this.loading_poi = true
      this.loading_cadastre = true

      this.$http
        .get('address', {
          params: {
            lon: coordinates[0],
            lat: coordinates[1],
            type: 'cadastre',
          },
        })
        .then((response) => {
          this.$store.commit('parcelle/INFOS_CADASTRE', response.data.cadastre)
          this.$store.commit(
            'parcelle/FEATURES_AUTOUR',
            this.addRefCadastaleToPropertiesFeaturesAutour(
              this.infos_cadastre.geospatial_autour.features,
            ),
          )
          this.informations.parcelles_voisines.forEach((parcelle) => {
            this.infos_cadastre.geospatial_autour.features.forEach((item) => {
              if (
                this.getRefParcelle(parcelle) ===
                this.getRefParcelle(item.parcelle)
              ) {
                parcelle.properties.id = item.parcelle.id
              }
            })
          })
          if (reset_dossier_promoteur) {
            this.setInformations()
          }
          this.callApisRisque(
            coordinates,
            response.data.cadastre.informations[0].code_insee,
          )
          this.loading_cadastre = false
          this.$refs.map_parcelle.loadDataToMap()
          this.updateZonesUrbaine(this.informations)
        })
        .catch((err) => {
          console.log('err :', err)
          if (err.response.status == 422) {
            this.loading_cadastre = false
            this.$store.commit(
              'parcelle/ERROR_PARCELLE_NOT_FOUND',
              err.response.data,
            )
          }
        })
      this.$store.commit('dossierpromoteur/setInformations', this.informations)
      this.$store.commit('parcelle/ERROR_PARCELLE_NOT_FOUND', null)
      this.old_label = this.informations.adresse

      if (
        !Object.keys(this.localisation).length ||
        !this.etude_commerciale.evolution_prix
      ) {
        this.$store.dispatch('poi/getPoi', coordinates).then((response) => {
          this.loading_poi = false
        })
      } else {
        this.loading_poi = false
      }
      if (!Object.keys(this.etude_commerciale).length) {
        this.$store
          .dispatch('etudeCommerciale/getPermisConstruireLocaux', coordinates)
          .then(() => {
            this.loading_permis_construire_locaux = false
          })
        this.$store
          .dispatch(
            'etudeCommerciale/getPermisConstruireLogements',
            coordinates,
          )
          .then(() => {
            this.loading_permis_construire_logements = false
          })
        this.$store
          .dispatch('etudeCommerciale/getDVF', coordinates)
          .then(() => {
            this.loading_dvf = false
          })
      } else {
        this.loading_permis_construire_locaux = false
        this.loading_permis_construire_logements = false
        this.loading_dvf = false
      }
    },
    setInformations() {
      let informations = this.infos_cadastre.informations[0]
      this.informations.commune = informations.nom
      this.informations.departement = informations.num_departement
      this.informations.code_postal = informations.code_postal
      this.informations.code_insee = informations.code_insee
    },
    saveImage(file) {
      this.informations.image_terrain = file
    },
    checkConfirmationForSelectParcelle(is_changed_section = false) {
      if (is_changed_section)
        this.message_confirmation =
          'Voulez-vous sauvegarder votre sélection avant de quitte cette page ?'
      if (this.is_corner_changed) {
        this.$refs.map_parcelle.$refs.elevations.addListenerClickOnCorner()
      }
      let is_valid_selected_parcelle =
        this.is_parcelle_changed || this.is_parcelle_aggregate
      if (is_valid_selected_parcelle) {
        if (
          this.old_current_parcelle.id !==
          this.informations.current_parcelle.properties.id
        ) {
          this.message_confirmation =
            'Si vous changer la parcelle principale, vos données seront supprimées, vous devez les ressaisir.'
        }
        this.showModal = true
      }
      return is_valid_selected_parcelle
    },
    setOldParcelles() {
      this.old_parcelle_voisines = [...this.informations.parcelles_voisines]
      this.old_current_parcelle = Object.assign(
        {},
        this.informations.current_parcelle,
      )
    },
    isAppelAProjet() {
      return this.informations.type_bien === 'Appel à projet'
    },
  },

  watch: {
    'informations.adresse': {
      handler() {
        this.searchAfterDebounce()
      },
    },
    'informations.is_not_visible_when_vendu': {
      handler(value) {
        this.$store.commit('dossierpromoteur/setIsNotVisibleWhenVendu', value)
      },
    },
    'informations.is_anonymous': {
      handler(value) {
        this.$store.commit('dossierpromoteur/setIsAnonymous', value)
        this.$store.commit('dossierpromoteur/setProgressSection', {
          section: 'urbanisme',
          value: value ? 100 : this.progressSection(this.getUrbanisme),
        })
        this.$store.commit('dossierpromoteur/setProgressSection', {
          section: 'localisation',
          value: value ? 100 : this.progressSection(this.getLocalisation),
        })
      },
    },
    informations: {
      deep: true,
      handler(value) {
        this.$store.commit('dossierpromoteur/setProgressSection', {
          section: 'informations',
          value: this.progressSection(value, ...this.filteredFields),
        })
      },
    },
    is_parcelle_changed(newValue, oldValue) {
      if (newValue) {
        this.setOldParcelles()
      } else if (
        this.old_current_parcelle.id !==
        this.informations.current_parcelle.properties.id
      ) {
        this.informations.center_parcelle_principale =
          this.$turf.pointOnFeature(
            this.informations.current_parcelle,
          ).geometry.coordinates

        this.setAddress(this.informations.center_parcelle_principale, true)
      }
    },
    is_parcelle_aggregate(newValue) {
      if (newValue) {
        this.setOldParcelles()
      }
    },
    api_is_loading(value) {
      if (!value) {
        this.informations = Object.assign(
          {},
          this.informations,
          this.informationsStore,
        )
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.matched.length > 0 && to.matched[1].path !== '/dossier-promoteurs') {
      next()
    }
    if (
      !this.checkConfirmationForSelectParcelle(true) &&
      Object.keys(this.informations.current_parcelle).length &&
      this.all_apis_is_loaded
    ) {
      this.saveInfos()
      next()
    } else if (
      !this.all_apis_is_loaded &&
      to.fullPath.includes('dossier-promoteurs')
    ) {
      this.$toaster.warning('Les données sont en cours de chargement')
    } else if (
      to.fullPath.includes('dossier-promoteurs') &&
      !this.loading_cadastre
    ) {
      this.$toaster.warning('Choisissez une adresse !')
    }
  },
}
</script>
<style lang="scss">
.informations-form {
  label {
    @apply capitalize text-xs text-gray-600 font-bold font-arial;
  }
}
</style>
