<template>
  <div class="flex-1 flex w-full relative flex-col map-parcelle">
    <div
      class="absolute w-full h-full bg-black z-20 opacity-50"
      :class="{
        hidden:
          !is_loading &&
          !isEmptyObject(informations.current_parcelle) &&
          !error_parcelle_not_found,
      }"
    ></div>
    <StylesMap class="z-10" :map="map" :moduleStore="moduleStore" />
    <div
      class="absolute bottom-0 left-0 sm:w-full z-10 flex px-6 justify-center py-4 flex-col w-86"
      style="background-color: #ffffffbd"
    >
      <div class="flex flex-col mb-4">
        <div class="flex mb-2">
          <span class="text-promy-purple-400 w-full font-extrabold">
            Parcelle :
          </span>
        </div>
        <div class="flex">
          <ChangeParcelle
            @addCornersWithElevation="
              $refs.elevations.addCornersWithElevation()
            "
            ref="ChangeParcelle"
            @getInfosParcelles="getInfosParcelles"
            :informations="informations"
            @checkConfirmationForSelectParcelle="
              $emit('checkConfirmationForSelectParcelle')
            "
          ></ChangeParcelle>
          <AggregateParcelle
            ref="AggregateParcelle"
            @addCornersWithElevation="
              $refs.elevations.addCornersWithElevation()
            "
            @getInfosParcelles="getInfosParcelles"
            :informations="informations"
          ></AggregateParcelle>
        </div>
      </div>
      <Elevations ref="elevations" :informations="informations"></Elevations>
    </div>
    <loader
      v-if="is_loading"
      class="absolute z-20 center-element"
      :size_bg_promy="0"
      :color="'#ffffff'"
    ></loader>
    <div
      class="absolute z-20 center-element bg-white p-4 rounded-lg"
      v-if="error_parcelle_not_found"
    >
      {{ error_parcelle_not_found }}
    </div>
    <div class="w-full h-full lg:h-60-screen" id="mapbox"></div>
  </div>
</template>
<script>
import Mapbox from '@/mixins/mapbox'
import parcelle from '@/mixins/cadastre/parcelle'
import batiment from '@/mixins/cadastre/batiment'
import informations_map from '@/mixins/dossierPromoteurs/informations/map'
import helpers from '@/mixins/helpers'
import calculeHelpers from '@/mixins/cadastre/calculeHelpers'
import sourcesAndLayers from '@/mixins/cadastre/sourcesAndLayers'
import Elevations from './Elevations'
import ChangeParcelle from './ChangeParcelle'
import AggregateParcelle from './AggregateParcelle'
import { mapGetters } from 'vuex'

export default {
  components: {
    Elevations,
    ChangeParcelle,
    AggregateParcelle,
  },
  mixins: [
    helpers,
    calculeHelpers,
    Mapbox,
    parcelle,
    batiment,
    sourcesAndLayers,
    informations_map,
  ],
  data() {
    return {
      moduleStore: 'parcelle',
    }
  },
  computed: {
    ...mapGetters({
      infos_cadastre: 'parcelle/infos_cadastre',
      markers_altimetrie: 'parcelle/markers_altimetrie',
    }),
  },
  props: {
    is_loading: {
      type: Boolean,
      required: true,
    },
    informations: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.initMap()
  },

  methods: {
    isEmptyObject(object) {
      return _.isEmpty(object)
    },
    getInfosParcelles(data) {
      this.$emit('getInfosParcelles', data)
    },

    initMap() {
      let coords = this.informations.center_parcelle_principale.length
        ? this.informations.center_parcelle_principale
        : [4.85, 45.75]
      this.$store.commit('defaultmap/SET_MAP', this.createMap(coords))
      this.map.on('load', () => {
        this.map.resize()
        this.setBaseLayers()
        this.loadDataToMap()
      })
      this.map.on('style.load', this.preserveLayers)
    },

    loadDataToMap() {
      if (!_.isEmpty(this.infos_cadastre)) {
        let vm = this
        this.removeMarkersAndAdditionalLayers(this.markers_altimetrie)
        if (!Object.keys(this.informations.current_parcelle).length) {
          this.informations.current_parcelle =
            this.infos_cadastre.geospatial.features.parcelle
          this.informations.current_parcelle.properties.adresse =
            this.informations.adresse
        }
        if (this.informations.altimetries.length) {
          this.$refs.elevations.addCornerCrossMarkersAltimetrie()
        } else {
          vm.$refs.elevations.addCornersWithElevation()
        }
        if (this.informations.taille_murs.length) {
          this.addLengthWellToMap(this.informations.taille_murs, 'parcelle')
        }

        this.$store.commit(
          'parcelle/PARCELLES_AUTOUR',
          this.getParcellesAutour(this.features_autour),
        )
        this.fitBoundsGeojsonInMap(
          this.$turf.featureCollection([
            this.informations.current_parcelle,
            ...this.informations.parcelles_voisines,
          ]),
        )

        this.addParcellesAutour(this.parcelles_autour)

        this.initParcellesVoisines(this.informations.parcelles_voisines)
        this.addPolygonsToMap(
          this.informations.current_parcelle,
          this.first_source_layers_parcelle,
        )
        if (!this.informations.surface_terrain) {
          this.setInfosParcelles([
            this.informations.current_parcelle,
            ...this.informations.parcelles_voisines,
          ])
        }
      }
    },
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove()
      this.$store.commit('defaultmap/SET_MAP', null)
    }
  },
}
</script>
<style lang="scss">
@import '../../../assets/styles/Mapbox/control-zoom.scss';
.map-parcelle {
  .mapboxgl-ctrl-group {
    border-radius: 10px;
    margin-top: 24px !important;
    margin-left: 24px !important;
  }
  .hidden-loader {
    display: none !important;
  }
  .translate-center {
    transform: translate(-50%, -50%);
  }
  .center-element {
    left: 50% !important;
    top: 50% !important;
    transform: translate(-50%, -50%) !important;
  }
}
</style>
<style lang="scss">
.icon-circle {
  @apply flex text-black justify-center text-base items-center font-bold  font-main w-5 h-5 border-solid  border-white border-2;
  border-radius: 50%;
}
.marker {
  background-color: #ffffff78 !important;
  color: black !important;
  font-weight: bold;
  font-size: 12px !important;
  border-radius: 15px;
  padding: 1px 4px 1px 4px;
}
.map-parcelle {
  .mapboxgl-popup-content {
    border-radius: 13px !important;
  }
}
</style>
